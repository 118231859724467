import {Fragment} from "react";
import {Col, Image, Row} from "react-bootstrap";
import underconstruction from '../../assets/underconstruction.jpg';
import Container from "react-bootstrap/Container";


function UnderConstruction() {
  return (
      <Fragment>
        <Container className="text-center overflow-y-auto">
          <Row>
            <Col>
              <Image
                  src={underconstruction} // Add the path to your logo image here
                  alt="Under Construction"
                  width="666"
                  rounded
              />
            </Col>
          </Row>
          <Row>
            <Col>
              Diese Seite befindet sich derzeit noch im Aufbau.
            </Col>
          </Row>
          <Row>
            <Col>
              Kontaktieren Sie uns oder besuchen Sie uns auf LinkedIn für weitere Informationen!
            </Col>
          </Row>
        </Container>
      </Fragment>
  );
}

export default UnderConstruction;
