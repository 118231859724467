import {Routes, Route} from 'react-router-dom';
import {Fragment} from "react";
import IBXNavbar from "./components/Navbar";
import UnderConstruction from "./pages/UnderConstruction";
import Imprint from "./pages/Imprint";
import Contact from "./pages/Contact";
// import Home from "./pages/Home";
// import About from "./pages/About";
// import Footer from "./components/Footer";


const App = () => {
    return (
        <Fragment>
            <IBXNavbar></IBXNavbar>
            <div className={"ibx-body-height ibx-h-pad-24 ibx-body-font"}>
                <Routes>
                    <Route path="/" element={<UnderConstruction/>}/>
                    <Route path="/imprint" element={<Imprint/>}/>
                    <Route path="/contact" element={<Contact/>}/>
                </Routes>
            </div>
            {/*<Footer/>*/}
        </Fragment>
    );
};

export default App;
