import Form from 'react-bootstrap/Form';
import {Button, Col, FloatingLabel, Row} from "react-bootstrap";

function Contact() {
  async function postLeadToSFDC() {
    const requestOptions = {
      method: 'POST',
      mode: 'no-cors',
    };

    const oid = document.getElementById('oid')?.value;
    const retUrl = document.getElementById('retURL')?.value;
    const salutation = document.getElementById('salutation')?.value;
    const firstName = document.getElementById('first_name')?.value;
    const lastName = document.getElementById('last_name')?.value;
    const company = document.getElementById('company')?.value;
    const title = document.getElementById('title')?.value;
    const email = document.getElementById('email')?.value;
    const phone = document.getElementById('phone')?.value;
    const description = document.getElementById('description')?.value;
    const leadSource = document.getElementById('lead_source')?.value;

    console.log(`https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8&oid=${oid}&retURL=${retUrl}&salutation=${salutation}&first_name=${firstName}&last_name=${lastName}&company=${company}&title=${title}&email=${email}&phone=${phone}&lead_source=${leadSource}&description=${description}`)

    await fetch(
  `https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8&oid=${oid}&retURL=${retUrl}&salutation=${salutation}&first_name=${firstName}&last_name=${lastName}&company=${company}&title=${title}&email=${email}&phone=${phone}&lead_source=${leadSource}&description=${description}`,
        requestOptions
    )
        .then((response) => response.text())
        .then((result) => console.log(result))
        .catch((error) => console.log('error', error));
  }

  return (
      <>
        <div className="ibx-form-container">
          <div className="ibx-form ">
            <h2>Want to get in contact?</h2>
            <span>Just fill put your details and we'll get in touch!</span>
            <br/>
            <br/>
          </div>
        </div>

        <div className="ibx-form-container">
          <Form id="web2lead" onSubmit={postLeadToSFDC} className="ibx-form ">
            <Form.Control id="oid" type="text" defaultValue="00D7R000004PJ1L" disabled hidden/>
            <Form.Control id="retURL" type="text" defaultValue="./" disabled hidden/>
            <Form.Control id="lead_source" type="text" defaultValue="Web" disabled hidden/>

            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <FloatingLabel label="Salutation" className="required">
                    <Form.Select required id="salutation">
                      <option></option>
                      <option value="Mr.">Mr.</option>
                      <option value="Ms.">Ms.</option>
                      <option value="Dr.">Dr.</option>
                      <option value="Prof.">Prof.</option>
                    </Form.Select>
                  </FloatingLabel>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <FloatingLabel label="First Name" className="required">
                    <Form.Control id="first_name" required type="text"/>
                  </FloatingLabel>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <FloatingLabel label="Last Name" className="required">
                    <Form.Control id="last_name" required type="text"/>
                  </FloatingLabel>
                </Form.Group>
              </Col>
            </Row>

            <Form.Group className="mb-3">
              <FloatingLabel label="Company" className="required">
                <Form.Control id="company" required type="text"/>
              </FloatingLabel>
            </Form.Group>
            <Form.Group className="mb-3">
              <FloatingLabel label="Position" className="required">
                <Form.Control id="title" required type="text"/>
              </FloatingLabel>
            </Form.Group>
            <Form.Group className="mb-3">
              <FloatingLabel label="Email" className="required">
                <Form.Control id="email" required type="email"/>
              </FloatingLabel>
            </Form.Group>
            <Form.Group className="mb-3">
              <FloatingLabel label="Phone">
                <Form.Control id="phone" type="phone"/>
              </FloatingLabel>
            </Form.Group>

            <Form.Group className="mb-3">
              <FloatingLabel label="Message">
                <Form.Control id="description" type="text" as="textarea" style={{ height: '200px' }}/>
              </FloatingLabel>
            </Form.Group>

            <Button variant="primary" type="submit">
              Submit
            </Button>
          </Form>
        </div>
      </>
  );
}

export default Contact;
