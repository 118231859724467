import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import {Image} from "react-bootstrap";
import ibxLogo from '../assets/logo.png';

function IBXNavbar() {
    return (
        <>
            <Navbar data-bs-theme="light" sticky="top" className="ibx-header-height bg-white">
                <Container>
                    <Navbar.Brand href="/"><Image src={ibxLogo} height={50}></Image></Navbar.Brand>
                    <Nav className="me-auto">
                        {/*<Nav.Link href="/about"><label className="">About Us</label></Nav.Link>*/}
                        <Nav.Link href="/contact">Contact Us</Nav.Link>
                        <Nav.Link href="/imprint">Imprint</Nav.Link>
                    </Nav>
                </Container>
            </Navbar>
        </>
    );
}

export default IBXNavbar;